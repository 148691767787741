<template>
  <div style="padding: 20px 20px 20px 20px">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>溯源管理</el-breadcrumb-item>
      <el-breadcrumb-item>溯源管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <el-card :style="{ height: screenHeight + 'px' }">
        <div>
          <a-tabs v-model="tabsKey">
            <a-tab-pane key="1" tab="溯源产品">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
                "
                v-auth="'sourceManagement-operation'"
              >
                <div style="margin-right: 10px">
                  <span>操作: &nbsp;</span>
                  <el-button size="small" @click="toAdd"
                    >新增{{ tabsKey | addTitleFilter }}</el-button
                  >
                </div>
              </div>
              <div
                :style="{
                  padding: '0px 5px',
                  height: screenHeight - 140 + 'px',
                  overflow: 'auto',
                }"
              >
                <a-row :gutter="10">
                  <a-col
                    :xs="{ span: 24 }"
                    :sm="{ span: 24 }"
                    :md="{ span: 24 }"
                    :lg="{ span: 12 }"
                    :xl="{ span: 8 }"
                    v-for="item in sourceManagementList"
                    :key="item.id"
                  >
                    <div
                      class="source_management_css"
                      @click="showSourceManagement(item)"
                      style="z-index: 90"
                    >
                      <div
                        style="
                          position: absolute;
                          right: 20px;
                          top: 10px;
                          z-index: 100;
                        "
                        v-if="authVerify.verify('sourceManagement-operation')"
                      >
                        <a
                          style="width: 21px; height: 21px; padding: 0px 2px"
                          @click.stop="deleteSourceManagement(item)"
                        >
                          <img
                            src="../../img/source/删除.png"
                            style="width: 17px; height: 17px"
                          />
                        </a>
                        <a
                          style="width: 21px; height: 21px; padding: 0px 2px"
                          @click.stop="editSourceManagement(item)"
                        >
                          <img
                            src="../../img/source/修改.png"
                            style="width: 17px; height: 17px; margin-left: 3px"
                          />
                        </a>
                      </div>
                      <a-row type="flex">
                        <a-col flex="70px">
                          <div style="height: 70px; width: 70px">
                            <el-image
                              style="width: 70px; height: 70px"
                              :src="item.coverImgAll"
                              :fit="'cover'"
                            ></el-image>
                          </div>
                        </a-col>
                        <a-col flex="auto">
                          <div style="margin-left: 15px">
                            <span
                              style="
                                font-size: 20px;
                                font-weight: 500;
                                color: black;
                              "
                              >{{ item.name }}</span
                            >
                            <div style="margin-top: 3px">
                              <span>品种:</span>
                              <span style="margin-left: 8px; color: black">{{
                                item.plantVarietyName
                              }}</span>
                            </div>
                            <div>
                              <span>批次:</span>
                              <span
                                style="margin-left: 8px; color: black"
                                v-if="
                                  item.sourceManagementBatchList.length == 0
                                "
                              >
                                暂无</span
                              >
                              <span
                                style="margin-left: 8px; color: black"
                                v-else
                              >
                                <a-badge
                                  :count="item.sourceManagementBatchList.length"
                                />
                              </span>
                            </div>
                          </div>
                        </a-col>
                      </a-row>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="销售管理" force-render>
              <div style="display: flex; justify-content: space-between">
                <div style="margin-left: 10px">
                  <span>溯源产品: &nbsp;</span>
                  <a-select
                    v-model="query.sourceManagementId"
                    placeholder="请选择溯源产品"
                    style="width: 150px"
                    @change="refresh(tabsKey)"
                    allow-clear
                  >
                    <a-select-option
                      v-for="obj in sourceManagementList"
                      :key="obj.id"
                    >
                      {{ obj.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div
                  style="margin-right: 10px"
                  v-auth="'sourceMarket-operation'"
                >
                  <span>操作: &nbsp;</span>
                  <el-button size="small" @click="toAdd"
                    >新增{{ tabsKey | addTitleFilter }}</el-button
                  >
                </div>
              </div>
              <div>
                <el-table
        header-row-class-name="my-table-head-style"
                  :height="screenHeight - 175"
                  style="margin-top: 0; margin-bottom: 0;margin-top: 14px;"
                  :data="sourceMarketList"
                  row-key="id"
                >
                  <el-table-column prop="sourceManagementName" label="溯源产品">
                  </el-table-column>
                  <el-table-column
                    prop="sourceManagementBatchCode"
                    label="溯源批次"
                  >
                  </el-table-column>
                  <el-table-column prop="type" label="销售渠道">
                    <template slot-scope="scope">
                      <span>{{ scope.row.type | marketTypeFilter }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="名称"> </el-table-column>
                  <el-table-column prop="address" label="地址">
                  </el-table-column>
                  <el-table-column
                    fixed="right"
                    width="200"
                    label="操作"
                    align="center"
                    v-if="authVerify.verify('sourceMarket-operation')"
                  >
                    <template slot-scope="scope">
                      <el-button
                        icon="el-icon-edit-outline"
                        size="mini"
                        @click="editSourceMarket(scope.row)"
                      >
                        编辑
                      </el-button>
                      <el-button
                        icon="el-icon-delete"
                        size="mini"
                        @click="deleteSourceMarket(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-pagination
                layout="total,sizes, prev, pager, next"
                :page-sizes="[10, 20, 30, 40]"
                :current-page="currentPage"
                :page-size="pgeSize"
                @current-change="pageChange"
                @size-change="sizeChange"
                :total="totalCount"
              >
              </el-pagination>
            </a-tab-pane>
            <a-tab-pane key="3" tab="证书管理">
              <div style="display: flex; justify-content: space-between">
                <div style="margin-left: 10px">
                  <span>溯源产品: &nbsp;</span>
                  <a-select
                    v-model="query.sourceManagementId"
                    placeholder="请选择溯源产品"
                    style="width: 150px"
                    @change="refresh(tabsKey)"
                    allow-clear
                  >
                    <a-select-option
                      v-for="obj in sourceManagementList"
                      :key="obj.id"
                    >
                      {{ obj.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div
                  style="margin-right: 10px"
                  v-auth="'sourceCertificate-operation'"
                >
                  <span>操作: &nbsp;</span>
                  <el-button size="small" @click="toAdd"
                    >新增{{ tabsKey | addTitleFilter }}</el-button
                  >
                </div>
              </div>
              <div>
                <el-table
        header-row-class-name="my-table-head-style"
                  :height="screenHeight - 175"
                  style="margin-top: 0; margin-bottom: 0;margin-top: 14px;"
                  :data="sourceCertificateList"
                  row-key="id"
                >
                  <el-table-column prop="sourceManagementName" label="溯源产品">
                  </el-table-column>
                  <el-table-column
                    prop="sourceManagementBatchCode"
                    label="溯源批次"
                  >
                  </el-table-column>
                  <el-table-column prop="certificateCode" label="证书编号">
                  </el-table-column>
                  <el-table-column
                    prop="certificateOrganization"
                    label="检测机构"
                  >
                  </el-table-column>
                  <el-table-column prop="startTime" label="颁发时间">
                    <template slot-scope="scope">
                      <span>{{ scope.row.startTime | dateFormatFull }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="endTime" label="失效时间">
                    <template slot-scope="scope">
                      <span>{{ scope.row.endTime | dateFormatFull }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="certificateImg" label="证书图片">
                    <template slot-scope="scope">
                      <img
                        v-if="scope.row.certificateImgAll"
                        :src="scope.row.certificateImgAll"
                        style="padding: 5px; height: 80px; width: auto"
                        alt=""
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    fixed="right"
                    width="200"
                    label="操作"
                    align="center"
                    v-if="authVerify.verify('sourceCertificate-operation')"
                  >
                    <template slot-scope="scope">
                      <el-button
                        icon="el-icon-edit-outline"
                        size="mini"
                        @click="editSourceCertificate(scope.row)"
                      >
                        编辑
                      </el-button>
                      <el-button
                        icon="el-icon-delete"
                        size="mini"
                        @click="deleteSourceCertificate(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-pagination
                layout="total,sizes, prev, pager, next"
                :page-sizes="[10, 20, 30, 40]"
                :current-page="currentPage"
                :page-size="pgeSize"
                @current-change="pageChange"
                @size-change="sizeChange"
                :total="totalCount"
              >
              </el-pagination>
            </a-tab-pane>
            <a-tab-pane key="4" tab="检测管理">
              <div style="display: flex; justify-content: space-between">
                <div style="margin-left: 10px">
                  <span>溯源产品: &nbsp;</span>
                  <a-select
                    v-model="query.sourceManagementId"
                    placeholder="请选择溯源产品"
                    style="width: 150px"
                    @change="refresh(tabsKey)"
                    allow-clear
                  >
                    <a-select-option
                      v-for="obj in sourceManagementList"
                      :key="obj.id"
                    >
                      {{ obj.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div
                  style="margin-right: 10px"
                  v-auth="'sourceDetection-operation'"
                >
                  <span>操作: &nbsp;</span>
                  <el-button size="small" @click="toAdd"
                    >新增{{ tabsKey | addTitleFilter }}</el-button
                  >
                </div>
              </div>
              <div>
                <el-table
        header-row-class-name="my-table-head-style"
                  :height="screenHeight - 175"
                  style="margin-top: 0; margin-bottom: 0;margin-top: 14px;"
                  :data="sourceDetectionList"
                  row-key="id"
                >
                  <el-table-column prop="sourceManagementName" label="溯源产品">
                  </el-table-column>
                  <el-table-column
                    prop="sourceManagementBatchCode"
                    label="溯源批次"
                  >
                  </el-table-column>
                  <el-table-column prop="principal" label="检测人员">
                  </el-table-column>
                  <el-table-column
                    fixed="right"
                    width="200"
                    label="操作"
                    align="center"
                    v-if="authVerify.verify('sourceDetection-operation')"
                  >
                    <template slot-scope="scope">
                      <el-button
                        icon="el-icon-edit-outline"
                        size="mini"
                        @click="editSourceDetection(scope.row)"
                      >
                        编辑
                      </el-button>
                      <el-button
                        icon="el-icon-delete"
                        size="mini"
                        @click="deleteSourceDetection(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-pagination
                layout="total,sizes, prev, pager, next"
                :page-sizes="[10, 20, 30, 40]"
                :current-page="currentPage"
                :page-size="pgeSize"
                @current-change="pageChange"
                @size-change="sizeChange"
                :total="totalCount"
              >
              </el-pagination>
            </a-tab-pane>
            <a-tab-pane key="5" tab="加工管理">
              <div style="display: flex; justify-content: space-between">
                <div style="margin-left: 10px">
                  <span>溯源产品: &nbsp;</span>
                  <a-select
                    v-model="query.sourceManagementId"
                    placeholder="请选择溯源产品"
                    style="width: 150px"
                    @change="refresh(tabsKey)"
                    allow-clear
                  >
                    <a-select-option
                      v-for="obj in sourceManagementList"
                      :key="obj.id"
                    >
                      {{ obj.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div
                  style="margin-right: 10px"
                  v-auth="'sourceProcess-operation'"
                >
                  <span>操作: &nbsp;</span>
                  <el-button size="small" @click="toAdd"
                    >新增{{ tabsKey | addTitleFilter }}</el-button
                  >
                </div>
              </div>
              <div>
                <el-table
        header-row-class-name="my-table-head-style"
                  :height="screenHeight - 175"
                  style="margin-top: 0; margin-bottom: 0;margin-top: 14px;"
                  :data="sourceProcessList"
                  row-key="id"
                >
                  <el-table-column prop="sourceManagementName" label="溯源产品">
                  </el-table-column>
                  <el-table-column
                    prop="sourceManagementBatchCode"
                    label="溯源批次"
                  >
                  </el-table-column>
                  <el-table-column prop="startTime" label="开始时间">
                    <template slot-scope="scope">
                      <span>{{ scope.row.startTime | dateFormatFull }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="endTime" label="结束时间">
                    <template slot-scope="scope">
                      <span>{{ scope.row.endTime | dateFormatFull }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="text" label="内容"> </el-table-column>
                  <el-table-column prop="principal" label="操作人员">
                  </el-table-column>
                  <el-table-column
                    fixed="right"
                    width="200"
                    label="操作"
                    align="center"
                    v-if="authVerify.verify('sourceProcess-operation')"
                  >
                    <template slot-scope="scope">
                      <el-button
                        icon="el-icon-edit-outline"
                        size="mini"
                        @click="editSourceProcess(scope.row)"
                      >
                        编辑
                      </el-button>
                      <el-button
                        icon="el-icon-delete"
                        size="mini"
                        @click="deleteSourceProcess(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-pagination
                layout="total,sizes, prev, pager, next"
                :page-sizes="[10, 20, 30, 40]"
                :current-page="currentPage"
                :page-size="pgeSize"
                @current-change="pageChange"
                @size-change="sizeChange"
                :total="totalCount"
              >
              </el-pagination>
            </a-tab-pane>
            <a-tab-pane key="6" tab="配送管理">
              <div style="display: flex; justify-content: space-between">
                <div style="margin-left: 10px">
                  <span>溯源产品: &nbsp;</span>
                  <a-select
                    v-model="query.sourceManagementId"
                    placeholder="请选择溯源产品"
                    style="width: 150px"
                    @change="refresh(tabsKey)"
                    allow-clear
                  >
                    <a-select-option
                      v-for="obj in sourceManagementList"
                      :key="obj.id"
                    >
                      {{ obj.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div
                  style="margin-right: 10px"
                  v-auth="'sourceDistribution-operation'"
                >
                  <span>操作: &nbsp;</span>
                  <el-button size="small" @click="toAdd"
                    >新增{{ tabsKey | addTitleFilter }}</el-button
                  >
                </div>
              </div>
              <div>
                <el-table
        header-row-class-name="my-table-head-style"
                  :height="screenHeight - 175"
                  style="margin-top: 0; margin-bottom: 0;margin-top: 14px;"
                  :data="sourceDistributionList"
                  row-key="id"
                >
                  <el-table-column prop="sourceManagementName" label="溯源产品">
                  </el-table-column>
                  <el-table-column
                    prop="sourceManagementBatchCode"
                    label="溯源批次"
                  >
                  </el-table-column>
                  <el-table-column prop="startTime" label="配送时间">
                    <template slot-scope="scope">
                      <span>{{ scope.row.startTime | dateFormatFull }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="endTime" label="到达时间">
                    <template slot-scope="scope">
                      <span>{{ scope.row.endTime | dateFormatFull }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="startArea" label="出发地">
                  </el-table-column>
                  <el-table-column prop="endArea" label="目的地">
                  </el-table-column>
                  <el-table-column prop="principal" label="负责人">
                  </el-table-column>
                  <el-table-column prop="consignee" label="收货人">
                  </el-table-column>
                  <el-table-column
                    fixed="right"
                    width="200"
                    label="操作"
                    align="center"
                    v-if="authVerify.verify('sourceDistribution-operation')"
                  >
                    <template slot-scope="scope">
                      <el-button
                        icon="el-icon-edit-outline"
                        size="mini"
                        @click="editSourceDistribution(scope.row)"
                      >
                        编辑
                      </el-button>
                      <el-button
                        icon="el-icon-delete"
                        size="mini"
                        @click="deleteSourceDistribution(scope.row)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-pagination
                layout="total,sizes, prev, pager, next"
                :page-sizes="[10, 20, 30, 40]"
                :current-page="currentPage"
                :page-size="pgeSize"
                @current-change="pageChange"
                @size-change="sizeChange"
                :total="totalCount"
              >
              </el-pagination>
            </a-tab-pane>
          </a-tabs>
        </div>
      </el-card>
    </div>

    <!--新增溯源产品-->
    <template>
      <div>
        <a-drawer
          title="新增溯源产品"
          width="400"
          placement="right"
          :closable="false"
          :visible="addSourceManagementVisible"
          @close="addSourceManagementVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceManagementForm"
              ref="plantVarietyRefFormAdd"
              :rules="sourceManagementRules"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="名称" prop="name">
                <a-input
                  placeholder="请输入名称"
                  v-model="sourceManagementForm.name"
                />
              </a-form-model-item>
              <a-form-model-item label="品种" prop="plantVarietyId">
                <a-select
                  v-model="sourceManagementForm.plantVarietyId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in plantVarietyList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="封面" prop="coverImg">
                <a-upload-dragger
                  name="file"
                  :multiple="false"
                  :action="httpUploadSrc"
                  :showUploadList="false"
                  :beforeUpload="handleBeforeUpload"
                  @change="imgHandleChange"
                >
                  <div v-if="!isShowImg">
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">点击上传图片</p>
                  </div>
                  <div v-else>
                    <img
                      v-if="sourceManagementForm.coverImgAll"
                      :src="sourceManagementForm.coverImgAll"
                      width="100%"
                      style="padding: 5px"
                      alt=""
                    />
                  </div>
                </a-upload-dragger>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addSourceManagementVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddSourManagement">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!--编辑溯源产品-->
    <template>
      <div>
        <a-drawer
          title="编辑溯源产品"
          width="400"
          placement="right"
          :closable="false"
          :visible="editSourceManagementVisible"
          @close="editSourceManagementVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceManagementForm"
              ref="plantVarietyRefFormEdit"
              :rules="sourceManagementRules"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="名称" prop="name">
                <a-input
                  placeholder="请输入名称"
                  v-model="sourceManagementForm.name"
                />
              </a-form-model-item>
              <a-form-model-item label="品种" prop="plantVarietyList">
                <a-select
                  v-model="sourceManagementForm.plantVarietyId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in plantVarietyList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="封面" prop="coverImg">
                <a-upload-dragger
                  name="file"
                  :multiple="false"
                  :action="httpUploadSrc"
                  :showUploadList="false"
                  :beforeUpload="handleBeforeUpload"
                  @change="imgHandleChange"
                >
                  <div v-if="!isShowImg">
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">点击上传图片</p>
                  </div>
                  <div v-else>
                    <img
                      v-if="sourceManagementForm.coverImgAll"
                      :src="sourceManagementForm.coverImgAll"
                      width="100%"
                      style="padding: 5px"
                      alt=""
                    />
                  </div>
                </a-upload-dragger>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editSourceManagementVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditSourManagement">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!--查看溯源产品-->
    <template>
      <div>
        <a-drawer
          title="溯源产品"
          width="800"
          placement="right"
          :closable="false"
          :visible="showSourceManagementVisible"
          @close="showSourceManagementVisible = false"
        >
          <div>
            <div>
              <a-row type="flex">
                <a-col flex="70px">
                  <div style="height: 70px; width: 70px">
                    <el-image
                      style="width: 70px; height: 70px"
                      :src="sourceManagement.coverImgAll"
                      :fit="'cover'"
                    ></el-image>
                  </div>
                </a-col>
                <a-col flex="auto">
                  <div style="margin-left: 30px">
                    <span
                      style="font-size: 20px; font-weight: 500; color: black"
                      >{{ sourceManagement.name }}</span
                    >
                    <div style="margin-top: 3px">
                      <span>品种:</span>
                      <span style="margin-left: 8px; color: black">{{
                        sourceManagement.plantVarietyName
                      }}</span>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <a-divider />
            <div style="margin-top: 1.5rem">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 1rem;
                "
              >
                <span style="font-size: 15px; font-weight: 600; color: black"
                  >检测证书</span
                >
              </div>
              <div>
                <el-table
        header-row-class-name="my-table-head-style"
                  style="margin-top: 0; margin-bottom: 0;margin-top: 14px;"
                  :data="sourceManagement.sourceCertificateList"
                  row-key="id"
                  size="mini"
                >
                  <el-table-column prop="certificateImg" label="证书图片">
                    <template slot-scope="scope">
                      <img
                        v-if="scope.row.certificateImgAll"
                        :src="scope.row.certificateImgAll"
                        style="padding: 5px; height: 80px; width: auto"
                        alt=""
                      />
                    </template>
                  </el-table-column>
                  <el-table-column prop="certificateCode" label="证书编号">
                  </el-table-column>
                  <el-table-column
                    prop="certificateOrganization"
                    label="检测机构"
                  >
                  </el-table-column>
                  <el-table-column prop="startTime" label="颁发时间">
                    <template slot-scope="scope">
                      <span>{{ scope.row.startTime | dateFormatFull }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <a-divider />
            <div style="margin-top: 1.5rem">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 1rem;
                "
              >
                <span style="font-size: 15px; font-weight: 600; color: black"
                  >销售渠道</span
                >
              </div>
              <div>
                <div>
                  <!--<a-empty
                      v-if="sourceManagement.sourceMarketList && sourceManagement.sourceMarketList.length == 0"
                  />
                  <el-empty
                      v-if="sourceManagement.sourceMarketList && sourceManagement.sourceMarketList.length == 0"
                  />-->
                </div>

                <div v-for="sm in sourceManagement.sourceMarketList">
                  <a-row>
                    <a-col :span="6"
                      ><span>类型:&nbsp;&nbsp;</span
                      ><span>{{ sm.type | marketTypeFilter }}</span></a-col
                    >
                    <a-col :span="6"
                      ><span>名称:&nbsp;&nbsp;</span
                      ><span>{{ sm.name }}</span></a-col
                    >
                    <a-col :span="6">
                      <div
                        style="
                          width: 100%;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        <span>地址:&nbsp;&nbsp;</span>
                        <a
                          :href="sm.address"
                          target="_blank"
                          v-if="sm.type === 'ONLINE'"
                          >{{ sm.address }}</a
                        >
                        <span v-else>{{ sm.address }}</span>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
            <a-divider />
            <div style="margin-top: 1.5rem">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 1rem;
                "
              >
                <span style="font-size: 15px; font-weight: 600; color: black"
                  >溯源批次</span
                >
                <span v-auth="'sourceManagementBatch-add'"
                  ><a-button @click="addBatch(sourceManagement)"
                    >添加</a-button
                  ></span
                >
              </div>
              <div>
                <div
                  class="source_management_detail_css"
                  v-for="clist in sourceManagement.sourceManagementBatchList"
                >
                  <div class="source_management_detail_css_c">
                    <a-row type="flex">
                      <a-col flex="auto">
                        <div style="margin-left: 15px; margin-top: 15px">
                          <div class="source_management_detail_text_css">
                            <span>溯源编码:</span>
                            <span style="margin-left: 8px; color: black">{{
                              clist.codeUrl
                            }}</span>
                          </div>
                          <div class="source_management_detail_text_css">
                            <span>种植批次:</span>
                            <span style="margin-left: 8px; color: black">{{
                              clist.plantSeedlingsCode
                            }}</span>
                          </div>
                          <div class="source_management_detail_text_css">
                            <span>上市时间:</span>
                            <span style="margin-left: 8px; color: black">{{
                              clist.startTime | dateFormatFull
                            }}</span>
                          </div>
                          <div class="source_management_detail_text_css">
                            <span>创建时间:</span>
                            <span style="margin-left: 8px; color: black">{{
                              clist.recordDate | dateFormatFull
                            }}</span>
                          </div>
                        </div>
                      </a-col>
                      <a-col flex="70px">
                        <div style="margin-top: 15px; margin-right: 20px">
                          <vue-qr
                            :text="sourceManagement.prefix + clist.codeUrl"
                            :margin="0"
                            colorDark="#000000"
                            colorLight="#fff"
                            :size="100"
                          ></vue-qr>
                        </div>
                      </a-col>
                    </a-row>

                    <div
                      style="margin-top: 10px; margin-right: 15px; float: right"
                    >
                      <div v-auth="'sourceManagementBatch-operation'">
                        <a class="batch_css" @click="deleteBatch(clist.id)">
                          <span>删除</span></a
                        >
                        <a
                          class="batch_css"
                          v-if="clist.putaway === false"
                          @click="changeBatchPutaway(clist)"
                        >
                          <span>上架</span></a
                        >
                        <a
                          class="batch_css"
                          v-else
                          @click="changeBatchPutaway(clist)"
                        >
                          <span>下架</span></a
                        >
                        <a
                          class="batch_css"
                          @click="
                            editBatch(clist, sourceManagement.plantVarietyId)
                          "
                        >
                          <span>编辑</span></a
                        >
                        <a
                          class="batch_css"
                          :href="sourceManagement.prefix + clist.codeUrl"
                          target="_blank"
                        >
                          <span>查看</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="clear: both; margin-bottom: 3rem"></div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="showSourceManagementVisible = false"
            >
              关闭
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>

    <!--新增溯源批次-->
    <template>
      <a-modal
        v-model="addSourceManagementBatchVisible"
        title="新增溯源批次"
        width="600px"
        @ok="toAddBatch"
        @cancel="addSourceManagementBatchVisible = false"
      >
        <a-form-model
          :model="sourceManagementBatchForm"
          ref="sourceManagementBatchFormRefAdd"
          :rules="sourceManagementBatchRules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 17 }"
        >
          <a-form-model-item label="种植批次" prop="plantSeedlings">
            <a-select
              v-model="sourceManagementBatchForm.plantSeedlings"
              placeholder="请选择溯源产品"
              style="width: 100%"
              allow-clear
            >
              <a-select-option v-for="obj in plantSeedlingsList" :key="obj.id">
                {{ obj.code }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="上市时间" prop="startTimeDate">
            <a-date-picker
              style="width: 100%"
              v-model="sourceManagementBatchForm.startTimeDate"
              show-time
            />
          </a-form-model-item>

          <a-form-model-item label="是否上架" prop="putaway">
            <a-select v-model="sourceManagementBatchForm.putaway">
              <a-select-option :value="true"> 上架 </a-select-option>
              <a-select-option :value="false"> 下架 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </template>
    <!--编辑溯源批次-->
    <template>
      <a-modal
        v-model="editSourceManagementBatchVisible"
        title="编辑溯源批次"
        width="600px"
        @ok="toEditBatch"
        @cancel="editSourceManagementBatchVisible = false"
      >
        <a-form-model
          :model="sourceManagementBatchForm"
          ref="sourceManagementBatchFormRefEdit"
          :rules="sourceManagementBatchRules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 17 }"
        >
          <a-form-model-item label="种植批次" prop="plantSeedlings">
            <a-select
              v-model="sourceManagementBatchForm.plantSeedlings"
              placeholder="请选择溯源产品"
              style="width: 100%"
              allow-clear
            >
              <a-select-option v-for="obj in plantSeedlingsList" :key="obj.id">
                {{ obj.code }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="上市时间" prop="startTimeDate">
            <a-date-picker
              style="width: 100%"
              v-model="sourceManagementBatchForm.startTimeDate"
              show-time
            />
          </a-form-model-item>

          <a-form-model-item label="是否上架" prop="putaway">
            <a-select v-model="sourceManagementBatchForm.putaway">
              <a-select-option :value="true"> 上架 </a-select-option>
              <a-select-option :value="false"> 下架 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </template>

    <!--新增配送管理-->
    <template>
      <div>
        <a-drawer
          title="新增配送管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="addSourceDistributionVisible"
          @close="addSourceDistributionVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceDistributionForm"
              ref="sourceDistributionFormRefAdd"
              :rules="sourceDistributionRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceDistributionForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceDistributionForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceDistributionForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="配送时间" prop="startTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceDistributionForm.startTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="到达时间" prop="endTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceDistributionForm.endTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="出发地" prop="startArea">
                <a-input
                  placeholder="请输入出发地"
                  v-model="sourceDistributionForm.startArea"
                />
              </a-form-model-item>
              <a-form-model-item label="目的地" prop="endArea">
                <a-input
                  placeholder="请输入目的地"
                  v-model="sourceDistributionForm.endArea"
                />
              </a-form-model-item>
              <a-form-model-item label="负责人" prop="principal">
                <a-input
                  placeholder="请输入负责人"
                  v-model="sourceDistributionForm.principal"
                />
              </a-form-model-item>
              <a-form-model-item label="收货人" prop="consignee">
                <a-input
                  placeholder="请输入收货人"
                  v-model="sourceDistributionForm.consignee"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addSourceDistributionVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddSourceDistribution">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!--编辑配送管理-->
    <template>
      <div>
        <a-drawer
          title="编辑配送管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="editSourceDistributionVisible"
          @close="editSourceDistributionVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceDistributionForm"
              ref="sourceDistributionFormRefEdit"
              :rules="sourceDistributionRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceDistributionForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceDistributionForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceDistributionForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="配送时间" prop="startTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceDistributionForm.startTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="到达时间" prop="endTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceDistributionForm.endTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="出发地" prop="startArea">
                <a-input
                  placeholder="请输入出发地"
                  v-model="sourceDistributionForm.startArea"
                />
              </a-form-model-item>
              <a-form-model-item label="目的地" prop="endArea">
                <a-input
                  placeholder="请输入目的地"
                  v-model="sourceDistributionForm.endArea"
                />
              </a-form-model-item>
              <a-form-model-item label="负责人" prop="principal">
                <a-input
                  placeholder="请输入负责人"
                  v-model="sourceDistributionForm.principal"
                />
              </a-form-model-item>
              <a-form-model-item label="收货人" prop="consignee">
                <a-input
                  placeholder="请输入收货人"
                  v-model="sourceDistributionForm.consignee"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editSourceDistributionVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditSourceDistribution">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>

    <!--新增加工管理-->
    <template>
      <div>
        <a-drawer
          title="新增加工管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="addSourceProcessVisible"
          @close="addSourceProcessVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceProcessForm"
              ref="sourceProcessFormRefAdd"
              :rules="sourceProcessRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceProcessForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceProcessForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceProcessForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="开始时间" prop="startTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceProcessForm.startTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="结束时间" prop="endTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceProcessForm.endTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="操作人员" prop="principal">
                <a-input
                  placeholder="请输入操作人员"
                  v-model="sourceProcessForm.principal"
                />
              </a-form-model-item>
              <a-form-model-item label="内容" prop="text">
                <a-textarea
                  v-model="sourceProcessForm.text"
                  placeholder="请输入..."
                  :auto-size="{ minRows: 3 }"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addSourceProcessVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddSourceProcess">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!--编辑加工管理-->
    <template>
      <div>
        <a-drawer
          title="编辑加工管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="editSourceProcessVisible"
          @close="editSourceProcessVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceProcessForm"
              ref="sourceProcessFormRefEdit"
              :rules="sourceProcessRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceProcessForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceProcessForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceProcessForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="开始时间" prop="startTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceProcessForm.startTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="结束时间" prop="endTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceProcessForm.endTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="操作人员" prop="principal">
                <a-input
                  placeholder="请输入负责人"
                  v-model="sourceProcessForm.principal"
                />
              </a-form-model-item>
              <a-form-model-item label="内容" prop="text">
                <a-textarea
                  v-model="sourceProcessForm.text"
                  placeholder="请输入..."
                  :auto-size="{ minRows: 3 }"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editSourceProcessVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditSourceProcess">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>

    <!--新增检测管理-->
    <template>
      <div>
        <a-drawer
          title="新增检测管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="addSourceDetectionVisible"
          @close="addSourceDetectionVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceDetectionForm"
              ref="sourceDetectionFormRefAdd"
              :rules="sourceDetectionRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceDetectionForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceDetectionForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceDetectionForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="操作人员" prop="principal">
                <a-input
                  placeholder="请输入负责人"
                  v-model="sourceDetectionForm.principal"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addSourceDetectionVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddSourceDetection">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!--编辑检测管理-->
    <template>
      <div>
        <a-drawer
          title="编辑检测管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="editSourceDetectionVisible"
          @close="editSourceDetectionVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceDetectionForm"
              ref="sourceDetectionFormRefEdit"
              :rules="sourceDetectionRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceDetectionForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceDetectionForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceDetectionForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="操作人员" prop="principal">
                <a-input
                  placeholder="请输入负责人"
                  v-model="sourceDetectionForm.principal"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editSourceDetectionVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditSourceDetection">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>

    <!--新增销售管理-->
    <template>
      <div>
        <a-drawer
          title="新增销售管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="addSourceMarketVisible"
          @close="addSourceMarketVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceMarketForm"
              ref="sourceMarketFormRefAdd"
              :rules="sourceMarketRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceMarketForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceMarketForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceMarketForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="销售渠道" prop="type">
                <a-select
                  v-model="sourceMarketForm.type"
                  placeholder="请选择销售渠道"
                >
                  <a-select-option value="ONLINE"> 线上 </a-select-option>
                  <a-select-option value="OFFLINE"> 线下 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="名称" prop="name">
                <a-input
                  placeholder="请输入线下门店名称/晚上平台名称"
                  v-model="sourceMarketForm.name"
                />
              </a-form-model-item>
              <a-form-model-item label="地址" prop="address">
                <a-input
                  placeholder="请输入门店地址/网店链接"
                  v-model="sourceMarketForm.address"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addSourceMarketVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddSourceMarket">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!--编辑销售管理-->
    <template>
      <div>
        <a-drawer
          title="编辑销售管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="editSourceMarketVisible"
          @close="editSourceMarketVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceMarketForm"
              ref="sourceMarketFormRefEdit"
              :rules="sourceMarketRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceMarketForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceMarketForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceMarketForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="销售渠道" prop="type">
                <a-select
                  v-model="sourceMarketForm.type"
                  placeholder="请选择销售渠道"
                >
                  <a-select-option value="ONLINE"> 线上 </a-select-option>
                  <a-select-option value="OFFLINE"> 线下 </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="名称" prop="name">
                <a-input
                  placeholder="请输入线下门店名称/晚上平台名称"
                  v-model="sourceMarketForm.name"
                />
              </a-form-model-item>
              <a-form-model-item label="地址" prop="address">
                <a-input
                  placeholder="请输入门店地址/网店链接"
                  v-model="sourceMarketForm.address"
                />
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editSourceMarketVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditSourceMarket">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>

    <!--新增证书管理-->
    <template>
      <div>
        <a-drawer
          title="新增证书管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="addSourceCertificateVisible"
          @close="addSourceCertificateVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceCertificateForm"
              ref="sourceCertificateFormRefAdd"
              :rules="sourceCertificateRules"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceCertificateForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceCertificateForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceCertificateForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="证书编号" prop="certificateCode">
                <a-input
                  placeholder="请输入证书编号"
                  v-model="sourceCertificateForm.certificateCode"
                />
              </a-form-model-item>
              <a-form-model-item
                label="检测机构"
                prop="certificateOrganization"
              >
                <a-input
                  placeholder="请输入检测机构"
                  v-model="sourceCertificateForm.certificateOrganization"
                />
              </a-form-model-item>
              <a-form-model-item label="颁发时间" prop="startTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceCertificateForm.startTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="失效时间" prop="endTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceCertificateForm.endTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="证书图片" prop="certificateImg">
                <a-upload-dragger
                  name="file"
                  :multiple="false"
                  :action="httpUploadSrc"
                  :showUploadList="false"
                  :beforeUpload="handleBeforeUploadCertificate"
                  @change="imgHandleChangeCertificate"
                >
                  <div v-if="!isShowImgCertificate">
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">点击上传图片</p>
                  </div>
                  <div v-else>
                    <img
                      v-if="sourceCertificateForm.certificateImgAll"
                      :src="sourceCertificateForm.certificateImgAll"
                      width="100%"
                      style="padding: 5px"
                      alt=""
                    />
                  </div>
                </a-upload-dragger>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addSourceCertificateVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toAddSourceCertificate">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!--编辑证书管理-->
    <template>
      <div>
        <a-drawer
          title="编辑证书管理"
          width="400"
          placement="right"
          :closable="false"
          :visible="editSourceCertificateVisible"
          @close="editSourceCertificateVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="sourceCertificateForm"
              ref="sourceCertificateFormRefEdit"
              :rules="sourceCertificateRules"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 17 }"
            >
              <a-form-model-item label="溯源产品" prop="sourceManagementId">
                <a-select
                  v-model="sourceCertificateForm.sourceManagementId"
                  placeholder="请选择溯源产品"
                  style="width: 100%"
                  allow-clear
                  @change="
                    sourceManagementIdChange(
                      sourceCertificateForm.sourceManagementId
                    )
                  "
                >
                  <a-select-option
                    v-for="obj in sourceManagementList"
                    :key="obj.id"
                  >
                    {{ obj.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                label="溯源批次"
                prop="sourceManagementBatchId"
              >
                <a-select
                  v-model="sourceCertificateForm.sourceManagementBatchId"
                  placeholder="请选择品种"
                >
                  <a-select-option
                    v-for="obj in sourceManagementBatchList"
                    :key="obj.id"
                  >
                    {{ obj.codeUrl }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="证书编号" prop="certificateCode">
                <a-input
                  placeholder="请输入证书编号"
                  v-model="sourceCertificateForm.certificateCode"
                />
              </a-form-model-item>
              <a-form-model-item
                label="检测机构"
                prop="certificateOrganization"
              >
                <a-input
                  placeholder="请输入检测机构"
                  v-model="sourceCertificateForm.certificateOrganization"
                />
              </a-form-model-item>
              <a-form-model-item label="颁发时间" prop="startTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceCertificateForm.startTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="失效时间" prop="endTimeDate">
                <a-date-picker
                  style="width: 100%"
                  v-model="sourceCertificateForm.endTimeDate"
                  show-time
                />
              </a-form-model-item>
              <a-form-model-item label="证书图片" prop="certificateImg">
                <a-upload-dragger
                  name="file"
                  :multiple="false"
                  :action="httpUploadSrc"
                  :showUploadList="false"
                  :beforeUpload="handleBeforeUploadCertificate"
                  @change="imgHandleChangeCertificate"
                >
                  <div v-if="!isShowImgCertificate">
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">点击上传图片</p>
                  </div>
                  <div v-else>
                    <img
                      v-if="sourceCertificateForm.certificateImgAll"
                      :src="sourceCertificateForm.certificateImgAll"
                      width="100%"
                      style="padding: 5px"
                      alt=""
                    />
                  </div>
                </a-upload-dragger>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editSourceCertificateVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditSourceCertificate">
              确认
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
import vueQr from "vue-qr";

export default {
  components: {
    vueQr,
  },
  name: "SourceManagement",
  data() {
    return {
      query: {
        sourceManagementId: [],
      },
      currentPage1: 1,
      pgeSize1: 10,
      totalCount1: 0,
      currentPage: 1,
      pgeSize: 10,
      totalCount: 0,
      sourceManagementList: [],
      sourceManagementBatchList: [],
      sourceManagement: {
        id: "",
        name: "",
        plantVarietyName: "",
        plantVarietyId: "",
        sourceManagementBatchList: [],
        coverImgAll: "",
      },
      sourceMarketList: [],
      sourceCertificateList: [],
      sourceDetectionList: [],
      sourceProcessList: [],
      sourceDistributionList: [],
      tabsKey: "1",
      addSourceManagementBatchVisible: false,
      editSourceManagementBatchVisible: false,
      addSourceManagementVisible: false,
      editSourceManagementVisible: false,
      addSourceMarketVisible: false,
      editSourceMarketVisible: false,
      addSourceCertificateVisible: false,
      editSourceCertificateVisible: false,
      addSourceDetectionVisible: false,
      editSourceDetectionVisible: false,
      addSourceProcessVisible: false,
      editSourceProcessVisible: false,
      addSourceDistributionVisible: false,
      editSourceDistributionVisible: false,

      plantVarietyList: [],
      httpUploadSrc:
        this._axios_env.SERVER +
        `/file/img?access_token=${localStorage
          .getItem("access_token")
          .substring(6, localStorage.getItem("access_token").length)}`,

      showSourceManagementVisible: false,
      sourceManagementRules: {
        name: [{ required: true, message: "请选择育苗品种!", trigger: "blur" }],
        plantVarietyId: [
          { required: true, message: "请选择育苗农场地块!", trigger: "blur" },
        ],
        coverImg: [
          { required: true, message: "请选择育苗农场地块!", trigger: "blur" },
        ],
      },
      plantSeedlingsList: [],
      sourceManagementBatchForm: {
        id: "",
        startTimeDate: "",
        startTimeStr: "",
        putaway: true,
        sourceManagementId: "",
        plantSeedlings: [],
      },
      sourceManagementBatchRules: {
        plantSeedlings: [
          { required: true, message: "请选择种植批次!", trigger: "blur" },
        ],
        startTimeDate: [
          { required: true, message: "请选择上市时间 !", trigger: "change" },
        ],
        putaway: [
          { required: true, message: "请输入是否上架!", trigger: "blur" },
        ],
      },
      sourceManagementForm: {
        id: "",
        name: "",
        plantVarietyId: [],
        coverImgAll: "",
        coverImg: "",
      },
      sourceDistributionForm: {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        startTimeDate: "",
        endTimeDate: "",
        principal: "",
        consignee: "",
        startArea: "",
        endArea: "",
        startTimeStr: "",
        endTimeStr: "",
      },
      sourceProcessForm: {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        startTimeDate: "",
        endTimeDate: "",
        principal: "",
        text: "",
        startTimeStr: "",
        endTimeStr: "",
      },
      sourceDetectionForm: {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        principal: "",
      },
      sourceDetectionRules: {
        sourceManagementId: [
          { required: true, message: "请选择溯源产品!", trigger: "blur" },
        ],
        sourceManagementBatchId: [
          { required: true, message: "请选择溯源批次 !", trigger: "blur" },
        ],
        principal: [
          { required: true, message: "请输入负责人!", trigger: "blur" },
        ],
      },
      sourceCertificateForm: {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        certificateCode: "",
        certificateOrganization: "",
        startTimeDate: "",
        endTimeDate: "",
        startTimeStr: "",
        endTimeStr: "",
        certificateImg: "",
        certificateImgAll: "",
      },
      sourceCertificateRules: {
        sourceManagementId: [
          { required: true, message: "请选择溯源产品!", trigger: "blur" },
        ],
        sourceManagementBatchId: [
          { required: true, message: "请选择溯源批次 !", trigger: "blur" },
        ],
        certificateCode: [
          { required: true, message: "请输入证书编号!", trigger: "blur" },
        ],
        certificateOrganization: [
          { required: true, message: "请输入检测机构!", trigger: "blur" },
        ],
        startTimeDate: [
          { required: true, message: "请选择颁发时间!", trigger: "change" },
        ],
        endTimeDate: [
          { required: true, message: "请选择失效时间!", trigger: "change" },
        ],
      },
      sourceMarketForm: {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        type: [],
        name: "",
        address: "",
      },
      sourceMarketRules: {
        sourceManagementId: [
          { required: true, message: "请选择溯源产品!", trigger: "blur" },
        ],
        sourceManagementBatchId: [
          { required: true, message: "请选择溯源批次 !", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择销售渠道!", trigger: "blur" }],
      },
      sourceDistributionRules: {
        sourceManagementId: [
          { required: true, message: "请选择溯源产品!", trigger: "blur" },
        ],
        sourceManagementBatchId: [
          { required: true, message: "请选择溯源批次 !", trigger: "blur" },
        ],
        principal: [
          { required: true, message: "请输入检测人员!", trigger: "blur" },
        ],
      },
      sourceProcessRules: {
        sourceManagementId: [
          { required: true, message: "请选择溯源产品!", trigger: "blur" },
        ],
        sourceManagementBatchId: [
          { required: true, message: "请选择溯源批次 !", trigger: "blur" },
        ],
        startTimeDate: [
          { required: true, message: "请选择开始时间!", trigger: "change" },
        ],
        endTimeDate: [
          { required: true, message: "请选择结束时间!", trigger: "change" },
        ],
        principal: [
          { required: true, message: "请输入负责人!", trigger: "blur" },
        ],
        text: [{ required: true, message: "请输入内容!", trigger: "blur" }],
      },
      screenWidth: document.documentElement.clientWidth,
      //屏幕高度
      screenHeight: document.documentElement.clientHeight - 120,
    };
  },
  watch: {
    tabsKey: function (value) {
      this.query.sourceManagementId = [];
      this.currentPage = 1;
      this.pgeSize = 10;
      this.totalCount = 0;
      this.refresh(value);
    },
  },
  filters: {
    addTitleFilter: function (value) {
      if (!value) return "";
      if (value === "1") {
        return "溯源产品";
      } else if (value === "2") {
        return "销售管理";
      } else if (value === "3") {
        return "证书管理";
      } else if (value === "4") {
        return "检测管理";
      } else if (value === "5") {
        return "加工管理";
      } else if (value === "6") {
        return "配送管理";
      }
    },
    marketTypeFilter: function (value) {
      if (!value) return "";
      if (value === "ONLINE") {
        return "线上";
      } else if (value === "OFFLINE") {
        return "线下";
      } else {
        return "";
      }
    },
  },
  computed: {
    isShowImg() {
      if (this.sourceManagementForm.coverImg === "") {
        return false;
      } else {
        const reg = /null/;
        return !reg.test(this.sourceManagementForm.coverImg);
      }
    },
    isShowImgCertificate() {
      if (this.sourceCertificateForm.certificateImg === "") {
        return false;
      } else {
        const reg = /null/;
        return !reg.test(this.sourceCertificateForm.certificateImg);
      }
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 120; //窗口高度
    };
  },
  created() {
    this.getSourceManagementList();
    this.getPlantVarietyList();
  },
  methods: {
    pageChange1(val) {
      console.log("pageChange");
      this.currentPage1 = val;
      this.refresh(this.tabsKey);
    },
    sizeChange1(val) {
      console.log("sizeChange");
      this.pgeSize1 = val;
      this.refresh(this.tabsKey);
    },
    pageChange(val) {
      console.log("pageChange");
      this.currentPage = val;
      this.refresh(this.tabsKey);
    },
    sizeChange(val) {
      console.log("sizeChange");
      this.pgeSize = val;
      this.refresh(this.tabsKey);
    },
    refresh(value) {
      this.sourceDistributionList = [];
      this.sourceProcessList = [];
      this.sourceDetectionList = [];
      this.sourceCertificateList = [];
      this.sourceMarketList = [];
      if (value === "1") {
        this.getSourceManagementList();
      } else if (value === "2") {
        this.getSourceMarketListPage();
      } else if (value === "3") {
        this.getSourceCertificateListPage();
      } else if (value === "4") {
        this.getSourceDetectionListPage();
      } else if (value === "5") {
        this.getSourceProcessListPage();
      } else if (value === "6") {
        this.getSourceDistributionListPage();
      }
    },
    /*配送管理*/
    addSourceDistribution() {
      this.cleanSourceDistribution();
      this.addSourceDistributionVisible = true;
        this.$refs.sourceDistributionFormRefAdd.resetFields();
    },
    toAddSourceDistribution() {
      this.$refs.sourceDistributionFormRefAdd.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.sourceDistributionForm.startTimeStr =
            this.sourceDistributionForm.startTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.sourceDistributionForm.endTimeStr =
            this.sourceDistributionForm.endTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.http.sourceManagement
            .createSourceDistribution(this.sourceDistributionForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.addSourceDistributionVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceDistributionListPage();
            });
        }
      });
    },
    cleanSourceDistribution() {
      this.sourceDistributionForm = {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        startTimeDate: "",
        endTimeDate: "",
        principal: "",
        consignee: "",
        startArea: "",
        endArea: "",
        startTimeStr: "",
        endTimeStr: "",
      };
    },
    editSourceDistribution(obj) {
      this.cleanSourceDistribution();
      this.sourceDistributionForm = {
        id: obj.id,
        sourceManagementId: obj.sourceManagementId,
        sourceManagementBatchId: obj.sourceManagementBatchId,
        startTimeDate: "",
        endTimeDate: "",
        principal: obj.principal,
        consignee: obj.consignee,
        startArea: obj.startArea,
        endArea: obj.endArea,
        startTimeStr: "",
        endTimeStr: "",
      };
      if (obj.startTime != null && obj.startTime != "") {
        this.sourceDistributionForm.startTimeDate = this.moment(obj.startTime);
      }
      if (obj.endTime != null && obj.endTime != "") {
        this.sourceDistributionForm.endTimeDate = this.moment(obj.endTime);
      }
      this.sourceManagementIdChange(obj.sourceManagementId);
      this.editSourceDistributionVisible = true;
        this.$refs.sourceDistributionFormRefEdit.resetFields();
    },
    toEditSourceDistribution() {
      this.$refs.sourceDistributionFormRefEdit.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.sourceDistributionForm.startTimeStr =
            this.sourceDistributionForm.startTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.sourceDistributionForm.endTimeStr =
            this.sourceDistributionForm.endTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.http.sourceManagement
            .updateSourceDistribution(this.sourceDistributionForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.editSourceDistributionVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceDistributionListPage();
            });
        }
      });
    },
    deleteSourceDistribution(obj) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.sourceManagement
            .deleteSourceDistribution({ id: obj.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getSourceDistributionListPage();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    async getSourceDistributionListPage() {
      const { data: res } =
        await this.http.sourceManagement.getSourceDistributionListPage({
          size: this.pgeSize,
          current: this.currentPage,
          sourceManagementId: this.query.sourceManagementId,
        });
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.sourceDistributionList = res.data.records;
      this.currentPage = res.data.current;
      this.totalCount = res.data.total;
    },

    /*加工管理*/
    addSourceProcess() {
      this.cleanSourceProcess();
      this.addSourceProcessVisible = true;
        this.$refs.sourceProcessFormRefAdd.resetFields();
    },
    toAddSourceProcess() {
      this.$refs.sourceProcessFormRefAdd.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.sourceProcessForm.startTimeStr =
            this.sourceProcessForm.startTimeDate.format("YYYY-MM-DD HH:mm:ss");
          this.sourceProcessForm.endTimeStr =
            this.sourceProcessForm.endTimeDate.format("YYYY-MM-DD HH:mm:ss");
          this.http.sourceManagement
            .createSourceProcess(this.sourceProcessForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.addSourceProcessVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceProcessListPage();
            });
        }
      });
    },
    editSourceProcess(obj) {
      this.cleanSourceProcess();
      this.sourceProcessForm = {
        id: obj.id,
        sourceManagementId: obj.sourceManagementId,
        sourceManagementBatchId: obj.sourceManagementBatchId,
        startTimeDate: "",
        endTimeDate: "",
        principal: obj.principal,
        text: obj.text,
        startTimeStr: "",
        endTimeStr: "",
      };
      if (obj.startTime != null && obj.startTime != "") {
        this.sourceProcessForm.startTimeDate = this.moment(obj.startTime);
      }
      if (obj.endTime != null && obj.endTime != "") {
        this.sourceProcessForm.endTimeDate = this.moment(obj.endTime);
      }
      this.sourceManagementIdChange(obj.sourceManagementId);
      this.editSourceProcessVisible = true;
        this.$refs.sourceProcessFormRefEdit.resetFields();
    },
    toEditSourceProcess() {
      this.$refs.sourceProcessFormRefEdit.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.sourceProcessForm.startTimeStr =
            this.sourceProcessForm.startTimeDate.format("YYYY-MM-DD HH:mm:ss");
          this.sourceProcessForm.endTimeStr =
            this.sourceProcessForm.endTimeDate.format("YYYY-MM-DD HH:mm:ss");
          this.http.sourceManagement
            .updateSourceProcess(this.sourceProcessForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.editSourceProcessVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceProcessListPage();
            });
        }
      });
    },
    cleanSourceProcess() {
      this.sourceProcessForm = {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        startTimeDate: "",
        endTimeDate: "",
        principal: "",
        text: "",
        startTimeStr: "",
        endTimeStr: "",
      };
    },
    deleteSourceProcess(obj) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.sourceManagement
            .deleteSourceProcess({ id: obj.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getSourceProcessListPage();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    async getSourceProcessListPage() {
      const { data: res } =
        await this.http.sourceManagement.getSourceProcessListPage({
          size: this.pgeSize,
          current: this.currentPage,
          sourceManagementId: this.query.sourceManagementId,
        });
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.sourceProcessList = res.data.records;
      this.currentPage = res.data.current;
      this.totalCount = res.data.total;
    },

    /*检测管理*/
    addSourceDetection() {
      this.cleanSourceDetection();
      this.addSourceDetectionVisible = true;
        this.$refs.sourceDetectionFormRefAdd.resetFields();
    },
    toAddSourceDetection() {
      this.$refs.sourceDetectionFormRefAdd.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.http.sourceManagement
            .createSourceDetection(this.sourceDetectionForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.addSourceDetectionVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceDetectionListPage();
            });
        }
      });
    },
    editSourceDetection(obj) {
      this.cleanSourceDetection();
      this.sourceDetectionForm = {
        id: obj.id,
        sourceManagementId: obj.sourceManagementId,
        sourceManagementBatchId: obj.sourceManagementBatchId,
        principal: obj.principal,
      };
      this.sourceManagementIdChange(obj.sourceManagementId);
      this.editSourceDetectionVisible = true;
        this.$refs.sourceDetectionFormRefEdit.resetFields();
    },
    toEditSourceDetection() {
      this.$refs.sourceDetectionFormRefEdit.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.http.sourceManagement
            .updateSourceDetection(this.sourceDetectionForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.editSourceDetectionVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceDetectionListPage();
            });
        }
      });
    },
    cleanSourceDetection() {
      this.sourceDetectionForm = {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        principal: "",
      };
    },
    deleteSourceDetection(obj) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.sourceManagement
            .deleteSourceDetection({ id: obj.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getSourceDetectionListPage();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    async getSourceDetectionListPage() {
      const { data: res } =
        await this.http.sourceManagement.getSourceDetectionListPage({
          size: this.pgeSize,
          current: this.currentPage,
          sourceManagementId: this.query.sourceManagementId,
        });
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.sourceDetectionList = res.data.records;
      this.currentPage = res.data.current;
      this.totalCount = res.data.total;
    },

    /*证书管理*/
    addSourceCertificate() {
      this.cleanSourceCertificate();
      this.addSourceCertificateVisible = true;
        this.$refs.sourceCertificateFormRefAdd.resetFields();
    },
    toAddSourceCertificate() {
      this.$refs.sourceCertificateFormRefAdd.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.sourceCertificateForm.startTimeStr =
            this.sourceCertificateForm.startTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.sourceCertificateForm.endTimeStr =
            this.sourceCertificateForm.endTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.http.sourceManagement
            .createSourceCertificate(this.sourceCertificateForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.addSourceCertificateVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceCertificateListPage();
            });
        }
      });
    },
    editSourceCertificate(obj) {
      this.cleanSourceCertificate();
      this.sourceCertificateForm = {
        id: obj.id,
        sourceManagementId: obj.sourceManagementId,
        sourceManagementBatchId: obj.sourceManagementBatchId,
        certificateCode: obj.certificateCode,
        certificateOrganization: obj.certificateOrganization,
        startTimeDate: "",
        endTimeDate: "",
        startTimeStr: "",
        endTimeStr: "",
        certificateImg: obj.certificateImg,
        certificateImgAll: obj.certificateImgAll,
      };
      if (obj.startTime != null && obj.startTime != "") {
        this.sourceCertificateForm.startTimeDate = this.moment(obj.startTime);
      }
      if (obj.endTime != null && obj.endTime != "") {
        this.sourceCertificateForm.endTimeDate = this.moment(obj.endTime);
      }
      this.sourceManagementIdChange(obj.sourceManagementId);
      this.editSourceCertificateVisible = true;
        this.$refs.sourceCertificateFormRefEdit.resetFields();
    },
    toEditSourceCertificate() {
      this.$refs.sourceCertificateFormRefEdit.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.sourceCertificateForm.startTimeStr =
            this.sourceCertificateForm.startTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.sourceCertificateForm.endTimeStr =
            this.sourceCertificateForm.endTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.http.sourceManagement
            .updateSourceCertificate(this.sourceCertificateForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.editSourceCertificateVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceCertificateListPage();
            });
        }
      });
    },
    cleanSourceCertificate() {
      this.sourceCertificateForm = {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        certificateCode: "",
        certificateOrganization: "",
        startTimeDate: "",
        endTimeDate: "",
        startTimeStr: "",
        endTimeStr: "",
        certificateImg: "",
        certificateImgAll: "",
      };
    },
    deleteSourceCertificate(obj) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.sourceManagement
            .deleteSourceCertificate({ id: obj.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getSourceCertificateListPage();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    async getSourceCertificateListPage() {
      const { data: res } =
        await this.http.sourceManagement.getSourceCertificateListPage({
          size: this.pgeSize,
          current: this.currentPage,
          sourceManagementId: this.query.sourceManagementId,
        });
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.sourceCertificateList = res.data.records;
      this.currentPage = res.data.current;
      this.totalCount = res.data.total;
    },

    /*销售管理*/
    addSourceMarket() {
      this.cleanSourceMarket();
      this.addSourceMarketVisible = true;
        this.$refs.sourceMarketFormRefAdd.resetFields();
    },
    toAddSourceMarket() {
      this.$refs.sourceMarketFormRefAdd.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.http.sourceManagement
            .createSourceMarket(this.sourceMarketForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.addSourceMarketVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceMarketListPage();
            });
        }
      });
    },
    editSourceMarket(obj) {
      this.cleanSourceMarket();
      this.sourceMarketForm = {
        id: obj.id,
        sourceManagementId: obj.sourceManagementId,
        sourceManagementBatchId: obj.sourceManagementBatchId,
        type: obj.type,
        name: obj.name,
        address: obj.address,
      };
      this.sourceManagementIdChange(obj.sourceManagementId);
      this.editSourceMarketVisible = true;
        this.$refs.sourceMarketFormRefEdit.resetFields();
    },
    toEditSourceMarket() {
      this.$refs.sourceMarketFormRefEdit.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.http.sourceManagement
            .updateSourceMarket(this.sourceMarketForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.editSourceMarketVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceMarketListPage();
            });
        }
      });
    },
    cleanSourceMarket() {
      this.sourceMarketForm = {
        id: "",
        sourceManagementId: [],
        sourceManagementBatchId: [],
        type: [],
        name: "",
        address: "",
      };
    },
    deleteSourceMarket(obj) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.sourceManagement
            .deleteSourceMarket({ id: obj.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getSourceMarketListPage();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    async getSourceMarketListPage() {
      const { data: res } =
        await this.http.sourceManagement.getSourceMarketListPage({
          size: this.pgeSize,
          current: this.currentPage,
          sourceManagementId: this.query.sourceManagementId,
        });
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.sourceMarketList = res.data.records;
      this.currentPage = res.data.current;
      this.totalCount = res.data.total;
    },

    /*其它*/
    getPlantSeedlingsList(id) {
      this.http.plantSeedlings
        .getPlantSeedlingsListByPlantVarietyId({
          plantVarietyId: id,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.plantSeedlingsList = response.data.data;
          }
        });
    },
    changeBatchPutaway(obj) {
      this.http.sourceManagement
        .updateSourceManagementBatch({
          id: obj.id,
          putaway: !obj.putaway,
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.$message.success(
              obj.putaway
                ? "下架成功!"
                : "上架成功, 可以通过扫码查看当前溯源产品信息!"
            );
            this.refreshDetail();
          } else {
            this.$message.warning(res.data.msg);
          }
          this.getSourceManagementList();
        });
    },
    deleteBatch(id) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.sourceManagement
            .deleteSourceManagementBatch({ id: id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getSourceManagementList();
                this.refreshDetail();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    addBatch(obj) {
      this.getPlantSeedlingsList(obj.plantVarietyId);
      this.cleanBatchForm();
      this.sourceManagementBatchForm.sourceManagementId = obj.id;
      this.addSourceManagementBatchVisible = true;
        this.$refs.sourceManagementBatchFormRefAdd.resetFields();
    },
    toAddBatch() {
      this.$refs.sourceManagementBatchFormRefAdd.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.sourceManagementBatchForm.startTimeStr =
            this.sourceManagementBatchForm.startTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.http.sourceManagement
            .createSourceManagementBatch(this.sourceManagementBatchForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.addSourceManagementBatchVisible = false;
                this.refreshDetail();
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceManagementList();
            });
        }
      });
    },
    async refreshDetail() {
      const id = this.sourceManagement.id;
      const { data: res } =
        await this.http.sourceManagement.getSourceManagementById({
          sourceManagementId: id,
        });
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.sourceManagement = res.data;
    },
    editBatch(obj, plantSeedlingsId) {
      this.getPlantSeedlingsList(plantSeedlingsId);
      this.cleanBatchForm();
      this.sourceManagementBatchForm = {
        id: obj.id,
        startTimeDate: "",
        startTimeStr: "",
        putaway: obj.putaway,
        sourceManagementId: obj.sourceManagementId,
        plantSeedlings: obj.plantSeedlings,
      };
      if (obj.startTime != null && obj.startTime != "") {
        this.sourceManagementBatchForm.startTimeDate = this.moment(
          obj.startTime
        );
      }
      if (obj.endTime != null && obj.endTime != "") {
        this.sourceManagementBatchForm.endTimeDate = this.moment(obj.endTime);
      }
      this.editSourceManagementBatchVisible = true;
        this.$refs.sourceManagementBatchFormRefEdit.resetFields();
    },
    toEditBatch() {
      this.$refs.sourceManagementBatchFormRefEdit.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.sourceManagementBatchForm.startTimeStr =
            this.sourceManagementBatchForm.startTimeDate.format(
              "YYYY-MM-DD HH:mm:ss"
            );
          this.http.sourceManagement
            .updateSourceManagementBatch(this.sourceManagementBatchForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.editSourceManagementBatchVisible = false;
                this.refreshDetail();
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceManagementList();
            });
        }
      });
    },
    cleanBatchForm() {
      this.sourceManagementBatchForm = {
        id: "",
        startTimeDate: "",
        startTimeStr: "",
        putaway: true,
        sourceManagementId: "",
        plantSeedlings: [],
      };
    },
    async sourceManagementIdChange(id) {
      this.sourceManagementBatchList = [];
      const { data: res } =
        await this.http.sourceManagement.getSourceManagementBatchList({
          id: id,
        });
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.sourceManagementBatchList = res.data;
    },
    toAdd() {
      const value = this.tabsKey;
      if (value === "1") {
        this.addSourManagement();
      } else if (value === "2") {
        this.addSourceMarket();
      } else if (value === "3") {
        this.addSourceCertificate();
      } else if (value === "4") {
        this.addSourceDetection();
      } else if (value === "5") {
        this.addSourceProcess();
      } else if (value === "6") {
        this.addSourceDistribution();
      }
    },
    async getSourceManagementList() {
      const { data: res } =
        await this.http.sourceManagement.getSourceManagementList({});
      if (res.status !== 200) {
        this.$message.error(res.msg);
      }
      this.sourceManagementList = res.data;
    },
    showSourceManagement(obj) {
      this.sourceManagement = {
        name: "",
        plantVarietyName: "",
        sourceManagementBatchList: [],
        coverImgAll: "",
      };
      this.sourceManagement = obj;
      this.showSourceManagementVisible = true;
    },
    getPlantVarietyList() {
      this.http.plantVariety.getPlantVarietyList().then((response) => {
        if (response.data.status == 200) {
          this.plantVarietyList = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    handleBeforeUpload(file, fileList) {
      const reg = /\.(png|jpg|jpeg|gif)$/gi;
      const filename = file.name;
      if (reg.test(filename) === false) {
        this.$message.error("请上传正确的图片格式！");
        return false;
      }
    },
    imgHandleChange({ file, fileList, event }) {
      if (file["response"] !== undefined) {
        this.sourceManagementForm.coverImgAll = file["response"].data.allPath;
        this.sourceManagementForm.coverImg = file["response"].data.path;
      }
    },

    handleBeforeUploadCertificate(file, fileList) {
      const reg = /\.(png|jpg|jpeg|gif)$/gi;
      const filename = file.name;
      if (reg.test(filename) === false) {
        this.$message.error("请上传正确的图片格式！");
        return false;
      }
    },
    imgHandleChangeCertificate({ file, fileList, event }) {
      if (file["response"] !== undefined) {
        this.sourceCertificateForm.certificateImgAll =
          file["response"].data.allPath;
        this.sourceCertificateForm.certificateImg = file["response"].data.path;
      }
    },

    addSourManagement() {
      this.cleanSourManagement();
      this.addSourceManagementVisible = true;
        this.$refs.plantVarietyRefFormAdd.resetFields();
    },
    cleanSourManagement() {
      this.sourceManagementForm = {
        id: "",
        name: "",
        plantVarietyId: [],
        coverImgAll: "",
        coverImg: "",
      };
    },
    editSourceManagement(obj) {
      this.cleanSourManagement();
      this.sourceManagementForm = {
        id: obj.id,
        name: obj.name,
        plantVarietyId: obj.plantVarietyId,
        coverImgAll: obj.coverImgAll,
        coverImg: obj.coverImg,
      };
      this.editSourceManagementVisible = true;
        this.$refs.plantVarietyRefFormEdit.resetFields();
    },
    deleteSourceManagement(obj) {
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.sourceManagement
            .deleteSourceManagement({ id: obj.id })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.getSourceManagementList();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },
    toAddSourManagement() {
      this.$refs.plantVarietyRefFormAdd.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.http.sourceManagement
            .createSourceManagement(this.sourceManagementForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.addSourceManagementVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceManagementList();
            });
        }
      });
    },
    toEditSourManagement() {
      this.$refs.plantVarietyRefFormEdit.validate((valid) => {
        if (valid) {
          console.log("添加");
          this.http.sourceManagement
            .updateSourceManagement(this.sourceManagementForm)
            .then((res) => {
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.editSourceManagementVisible = false;
              } else {
                this.$message.warning(res.data.msg);
              }
              this.getSourceManagementList();
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.source_management_css {
  padding: 15px;
  height: 115px;
  width: 100%;
  margin-bottom: 10px;
  background: #fcfcfc;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.3rem;
}

.source_management_css:hover {
  border: 1px solid rgba(240, 173, 78, 0.46);
  background: rgba(240, 173, 78, 0.07);
  box-shadow: rgba(143, 143, 143, 0.51) 1px 1px 15px 1px;
}

.source_management_detail_css {
  padding: 2px 8px;
  height: 160px;
  width: 50%;
  margin-bottom: 10px;
  float: left;
}

.source_management_detail_css_c {
  background: #fcfcfc;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.source_management_detail_css:hover {
  /*border: 1px solid rgba(240, 173, 78, 0.46);
    background: rgba(240, 173, 78, .07);
    box-shadow: rgba(143, 143, 143, 0.51) 1px 1px 15px 1px;*/
}

.batch_css {
  color: #575757;
  margin-left: 10px;
}

.batch_css:hover {
  color: #1890ff;
}

.source_management_detail_text_css {
  width: 200px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*.cardHover{
    box-shadow: rgba(143, 143, 143, 0.51) 2px 2px 5px 1px;
  }*/
</style>
